<template>
    <div>
      <!-- Page Heading -->
        <v-toolbar flat>
          <pageHeading pageTitle="Instore Rider Payment"/>
          <v-spacer></v-spacer>
          
          <!-- Save Dialog with button -->
          <v-dialog v-model="dialog_payment" max-width="600px">
            <template v-slot:activator="{ on }">
                <v-btn 
                  v-show="canDo && canDo.can.includes('import')"
                  class="mx-2" depressed dark color="primary" v-on="on">
                  <v-icon left dark>mdi-download</v-icon> ยืนยันการจ่ายเงิน
                </v-btn>
            </template>
            <!-- Modal Content -->
            <v-card>
              <v-card-title class="justify-center">
                <span class="headline">ยืนยันการจ่ายเงิน</span>
              </v-card-title>
              <v-card-text>
                <p class="text-center"> จ่ายทันที <span>{{ amount_paid }}</span> บาท  <span>{{ amount_tx }}</span> รายการ</p>
                <p class="text-center"> รอการ approve <span>{{ waiting_paid }}</span> บาท  <span>{{ waiting_tx }}</span> รายการ</p>
                <br>

                <v-list tag='ul' v-if="payment_validate_now.length > 0">
                    <h3 class="red--text">Error paid now: </h3>
                    <template v-for="(item) in payment_validate_now">
                        <v-list-item tag='li' :key='item.id' style="min-height:20px;">
                        <span class="red--text">{{ "Order ID : " + item.order_id + " | Reason : " + item.reason }}</span>
                        </v-list-item>
                    </template>
                </v-list>
                <v-list tag='ul' v-if="payment_validate_approve.length > 0">
                    <h3 class="red--text">Error approve: </h3>
                    <template v-for="(item_approve) in payment_validate_approve">
                        <v-list-item tag='li' :key='item_approve.id' style="min-height:20px;">
                        <span class="red--text">{{ "Order ID : " + item_approve.order_id + " | Reason : " + item_approve.reason }}</span>
                        </v-list-item>
                    </template>
                </v-list>

                <template>
                    <v-data-table
                        :headers="selectedHeaders"
                        :items="selectedTable"
                        :items-per-page="100"
                        class="elevation-1"
                        dense
                        >
                    </v-data-table>
                </template>
 
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closePayment">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="submitPayment">Save</v-btn>
              </v-card-actions>
            </v-card>
            <!-- End Modal Content -->
          </v-dialog>
  
        </v-toolbar> 


      <v-dialog v-model="dialogSMS" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit SMS template</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" v-for="(item) in sms_template" :key="item.id" class="pb-0 pt-0">
                      <!-- {{ item }} -->
                        <v-row>
                          <v-col cols="4" sm="4" md="4" class="pb-0" v-if="item.param_name">
                            <v-text-field v-model="item.prefix" :rules="validate_rules.name" label="Prefix"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12" class="pb-0" v-else>
                            <v-text-field v-model="item.prefix" :rules="validate_rules.name" label="Prefix"></v-text-field>
                          </v-col>
                          <v-col cols="4" sm="4" md="4" class="pb-0" v-if="item.param_name">
                            <div>
                              <p style="font-size:10px;" class="mb-1">param name</p>
                              <span >{{item.param_name}}</span>
                              <hr>
                            </div>
                          </v-col>
                          <v-col cols="4" sm="4" md="4" class="pb-0"  v-if="item.param_name">
                            <v-text-field v-model="item.suffix" :rules="validate_rules.name" label="Suffix"></v-text-field>
                          </v-col>
                        </v-row>
                    </v-col>
                  </v-row>
                  <hr v-if="editedItemSMS.sms_template">
                  <br v-if="editedItemSMS.sms_template">
                  <p v-if="editedItemSMS.sms_template">ตัวอย่างข้อความ sms : </p>
                  <span v-for="(item) in editedItemSMS.sms_template" :key="item.id" style="font-style:oblique;">
                    {{item.prefix}} {{paramName(item.param_name)}} {{item.suffix}}
                  </span>

                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <v-divider></v-divider>
      <div class="content-wrap">
          <v-toolbar class="search-tools pa-0 mb-4" flat>
            <v-col class="d-flex" cols="10" sm="10">
                  <v-select
                    :items="instoreImportComplete"
                    :item-text="'filename'"
                    :item-value="'id'"
                    label="Select import file"
                    v-model="instore_file"
                    no-data-text="Select.."
                    dense
                    hide-details
                  ></v-select>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
            <v-btn class="mx-2" depressed dark small color="primary" @click="loadHistoryImport(options);">
              Load
            </v-btn>
          </v-toolbar>
          <v-toolbar class="search-tools pa-0 mb-4" flat>
            <!-- <v-spacer></v-spacer> -->
            <v-col class="d-flex" cols="8" sm="8">
              <v-btn class="mx-2" :disabled="!sms_button" depressed dark small color="primary" @click="loadSMSdialog(options);">
              SMS
              </v-btn>
            </v-col>
            <v-col class="d-flex" cols="4" sm="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-toolbar>
          <v-data-table 
              :headers="headers"
              :items="instore_datas" 
              :options.sync="options"
              item-key="id"
              :search="search"
              :loading="loadingDataTable"
              show-select
              @toggle-select-all="selectAll"
              v-model="selectedList"
              :footer-props="{
                'items-per-page-options': [25, 100, 500]
              }"
              class="text-small"
              multi-sort
              no-data-text="There is no data"
          >

          <template #item.updatedAt="{value}">
              {{ value | datetime}}
          </template>

          <template v-slot:[`item.driver`]="{item}">
            {{ item.match_employee ? item.match_employee.name_th : item.file_data.driver }}
          </template>
            
          </v-data-table>
  
          <v-row>
            <v-col cols="12" sm="4" class="text-right">
              จ่ายทันที <span>{{ amount_paid }}</span> บาท  <span>{{ amount_tx }}</span> รายการ
            </v-col>
            <v-col cols="12" sm="4" class="text-right">
              รอการ approve <span>{{ waiting_paid }}</span> บาท  <span>{{ waiting_tx }}</span> รายการ
            </v-col>
            <v-col cols="12" sm="4" class="text-right">
              <v-btn class="mx-2" depressed dark small color="primary" @click="paidPaymentInstore();">
                Paid
              </v-btn>
            </v-col>
          </v-row>
      </div>
    </div>
  </template>
  <script>
  import moment from 'moment';
  import { mapGetters, mapActions } from 'vuex';
  import pageHeading from '@/components/common/pageHeading.vue';
  import _ from 'lodash';
import { thistle } from 'color-name';
  
  export default {
    name: 'InstoreDriver',
    components: {
        pageHeading
    },
    data: () => ({
      amount_paid : 0,
      amount_tx : 0,
      waiting_paid : 0,
      waiting_tx : 0,
      selectedList : [],
      selectedTable : [],
      search: '',
      payment_validate_now : [],
      payment_validate_approve : [],
      dialog_payment: false,
      export_datePicker:false,
      export_dialog: false,
      datePicker: false,
      filterDatePicker: false,
      upload_file: null,
      instore_file : null,
      instore_datas :[],
      editedItem: {
        id: -1,
        import_file: null,
        replace: true
      },
      defaultItem: {
        id: -1,
        import_file: null,
        replace: true
      },
      dialogSMS : false,
      sms_button : false,
      sms_template : null,
      editedItemSMS: {
        id: -1,
        incentive_program_id: '',
        is_deductable : false,
        incentive_date: '',
        topup_file: null,
        incentive_program_is_deduct : false,
        sms_template : null,
        replace: true,
        wallet_code : null
      },
      form_valid : false,
      validate_rules : {
          required: v => !!v || 'This field is required',
          select2: v => {
            if(typeof v === 'object'){
              return (v.value !== '') || 'This field is required';
            } else {
              return true
            }
          },
          filesize:  v => !v || v.size < 2000000 || 'Updload size should be less than 2 MB!',
          filetype:  v => {
            if(v){
              var ext = v.name.split('.').pop();
              return ["xlsx"].includes(ext) || 'Please upload .xlsx file only';
            } else {
              return 'This field is required'
            }
          }
          // excel type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      options: { 
          page: 1,
          itemsPerPage: 25,
          sortBy: ['tmw_transaction_date'],
          sortDesc: [true],
          multiSort: true,
          mustSort: true,
          import_file : '',
          filter: {
              keyword: '',
              transaction_type: '',
              date_range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          },
      },
      selectedHeaders: [
        { text: "Employee No", value: "match_employee.employee_no", width: 120 , align:'center' },
        { text: "จำนวน Order", value: "count" , width: 120, align:'right'},
        { text: "รวม", value: "driver_getpaid_delivery_all" , width: 120 , align:'right'},
        ],
    }),
    watch: {
      'options.sortBy': function (newVal, oldVal){
           //to work with changes in someOtherProp
          if(newVal){
            // this.getTMWReport(this.options);
          }
       },
      dialog (val) {
        val || this.close()
      },
      filterDatePicker (val){
        if(val && this.options.filter.date_range.length == 0){
          this.options.filter.date_range = [ moment().subtract(0, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
        }
      },

      selectedList() {
        let selected  = _.map(this.selectedList);
        let selected_id  = _.map(this.selectedList,'id');
        this.waiting_paid = selected.reduce((a, b) => { return a + (b.match_order_no ? b.match_order_no["view_lastest_order.driver_delivery_fee"] : 0);},0);
        this.waiting_tx = (selected.length);

        this.amount_paid = this.instore_datas.reduce((a, b) => { return !selected_id.includes(b.id) ?  a + (b.match_order_no ? b.match_order_no["view_lastest_order.driver_delivery_fee"] : 0) : a},0);
        this.amount_tx = (this.instore_datas.length - selected.length);
    },

    },
    methods: {
      ...mapActions(['genCan','checkImportInstoreFile','getInstoreImportOrderPayment','getImportInstoreComplete','confirmInstorePayment','getSMSTemplateTask','updateSMSTemplate']),
      clearFilterDaterange(){
        this.options.filter.date_range = [];
      },
      async loadHistoryImport() {
  
        const getDataStoreFile = await this.getInstoreImportOrderPayment(this.instore_file,true);
        this.sms_template = await this.getSMSTemplateTask(this.instore_file);
        if(this.sms_template != null){
          this.sms_button = true
        }else{
          this.sms_button = false
        }

        for(let item in getDataStoreFile.import_content){
          let ordertime = ''

          if(getDataStoreFile.import_content[item].file_data['order time']){
            let order_hour = Math.floor(Math.round(getDataStoreFile.import_content[item].file_data['order time'] * 1440) / 60) + ''
            let order_time = (Math.round(getDataStoreFile.import_content[item].file_data['order time'] * 1440) % 60) + ''
            ordertime = getDataStoreFile.import_content[item].file_data.date + ' ' + order_hour + ':' + order_time
          }

          getDataStoreFile.import_content[item].file_data['order time'] = ordertime
        }

        this.instore_datas = getDataStoreFile.import_content.filter(item => { return item.ticket_token == null && item.superseded_by_id == null && item.reconciled_order == null}).map( d => {
            return {
              ...d,
              driver_phone: d.file_data["edit phone driver"] ? d.file_data["edit phone driver"] : d.file_data["phone driver"],
            }
          });

        this.amount_paid = this.instore_datas.reduce((a, b) => { return a + (b.match_order_no != null ? b.match_order_no["view_lastest_order.driver_delivery_fee"] : 0);},0);
        this.amount_tx = this.instore_datas.length
  
      },
  
      async paidPaymentInstore() {
        let group_selected = this.selectedList.filter((v,i,a) => a.findIndex(v2 => {return v2.match_employee != null && v.match_employee != null ? (v2.match_employee.employee_no === v.match_employee.employee_no) : ''}) === i)
        for(let item in group_selected) {
            let aaa = this.selectedList.filter(x => { return x.match_employee ? x.match_employee.employee_no === group_selected[item].match_employee.employee_no : ''})
            console.log(aaa)
            group_selected[item].count = aaa.length
            group_selected[item].driver_getpaid_delivery_all = aaa.reduce((a, b) => { return a + (b.match_order_no != null ? b.match_order_no['view_lastest_order.driver_delivery_fee'] : 0);},0)
        }
        console.log(group_selected)
        this.selectedTable = group_selected;

        this.dialog_payment = true
      },
  
      closePayment () {
        this.dialog_payment = false
      },

      selectAll(event) {
        console.log(event)
      },
  
      async submitPayment () {
        let approve_order_no  = _.map(this.selectedList,'id');
        let paid_order_no = this.instore_datas.map((element) => { if(!approve_order_no.includes(element.id)) {return element.id}}).filter(item => item != undefined)
        console.log(paid_order_no,approve_order_no)

        let payment_data = {
            'task_id' : this.instore_file,
            'paid_order_no' : paid_order_no,
            'approve_order_no' : approve_order_no,
        }

        let confirmPayment = await this.confirmInstorePayment(payment_data);
        console.log(confirmPayment)

        if(confirmPayment.conflict_paid_now.length > 0){
            this.payment_validate_now = confirmPayment.conflict_paid_now;
        }
        if(confirmPayment.conflict_require_approve.length > 0){
            this.payment_validate_approve = confirmPayment.conflict_require_approve;
        }

        if(confirmPayment.status == 'processing' && confirmPayment.conflict_require_approve.length == 0 && confirmPayment.conflict_paid_now.length == 0){
            this.closePayment()
            window.location.reload()
        }
      },

      loadSMSdialog () {
        this.dialogSMS = true
      },

      close () {
        this.dialogSMS = false
        setTimeout(() => {
          this.editedItemSMS = _.cloneDeep(this.defaultItem)
          this.editedIndex = -1
          this.$refs.form.resetValidation() // prevent validate error show again when click add
        }, 300)
      },

      save () {
        console.log(this.sms_template)
        if(this.form_valid){
          let payload = {
            'sms_template' : this.sms_template,
            'task_id' : this.instore_file
          }
          this.updateSMSTemplate(payload);
          this.close()
        }
      },

  
    },
    computed: {
      ...mapGetters(['canDo','loadingDataTable','tmwReport','transactionTypes','instoreImportComplete']),
      dataLisings() { 
          var res = [];
          return  res;
      },
      totalResult(){
          return (this.tmwReport ? this.tmwReport.count : null) || 0;
      },
      headers(){
        return [
          {text: "Id", value: 'file_data.id',align: 'center',width: '150px',sortable:false},
          {text: "Store Name", value: 'file_data.store name', align: 'center',width: '120px'},
          {text: "Order Time", value: 'file_data.order time', align: 'left',width: '120px',sortable: false},
          {text: "Customer Phone", value: 'file_data.phone customer', align: 'left',width: '150px'},
          {text: "Driver", value: 'driver', align: 'center',width: '120px'},
          {text: "Driver Phone", value: 'driver_phone', align: 'left',width: '150px'},
          {text: "Order No.", value: 'match_order_no.order_no', align: 'center',width: '150px',sortable:false},
          {text: "Rider No ใหม่", value: 'match_employee.employee_no', align: 'center',width: '120px',sortable:false},
          {text: "Rider No เดิม", value: 'last_employee.employee_no', align: 'center',width: '120px',sortable:false},
          {text: "Updated Date", value: 'updatedAt', align: 'center',width: '150px'},
        ]
      },
      incentive_date_formatted () {
          return this.editedItem.incentive_date ? moment(this.editedItem.incentive_date).format('DD-MM-YYYY') : '';
      },
      filter_date_formatted () {
        if(!this.options.filter.date_range) 
          return '';
          
        var date_format = this.options.filter.date_range.map(x => moment(x).format('DD-MM-YYYY'));
          return typeof date_format == 'object' ? date_format.join(' - ') : '';
        
      },
    },
    mounted() {
      this.genCan();
      this.getImportInstoreComplete();
    }
  };
  </script>
  <style scoped>
  .v-data-table {
    max-width: 100%
  }
  </style>